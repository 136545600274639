<template>
    <div  class="appAside fs-14" :class="sidebarToggle ? 'appAside-activer' : 'closen-assider'">
        <div class="aside-content">
            <div class="w-full">
            <div class="asside-header">
                <div class="asside-header-icon">
                    <img src="@/assets/img/emblem.svg" alt="">
                </div>
                <div v-show="sidebarToggle" class="asside-header-content">
                    <div class="asside-header-text">
                        <!-- PASSPORTBINO -->
                         {{ $t('app_name') }}
                    </div>
                   
                </div>
            </div>
            <ul class="routes scroll default" style="max-height: calc(100vh - 380px);">
                <div class="asside-linker" v-if="!['tf_operator'].includes(role)">
                    <router-link tag="div" class="asside-g-link" to="/" id="link_dashboard" :class="$route.name=='home'?'router-link-active':''" active-class="router-link-svg" exact>
                        <li class="asside-linker-hover">
                            <div class="aside-icon">
                                <svg version="1.1" id="Layer_1" width="30" height="30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <path d="M124.52,239.304H16.696C7.475,239.304,0,246.78,0,256v215.647c0,9.22,7.475,16.696,16.696,16.696H124.52

    c9.22,0,16.696-7.475,16.696-16.696V256C141.215,246.78,133.74,239.304,124.52,239.304z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M309.912,23.658H202.089c-9.22,0-16.696,7.475-16.696,16.696v431.293c0,9.22,7.475,16.696,16.696,16.696h107.824

    c9.22,0,16.696-7.474,16.696-16.695V40.353C326.608,31.133,319.133,23.658,309.912,23.658z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M495.304,131.48H387.481c-9.22,0-16.696,7.475-16.696,16.696v323.47c0,9.22,7.475,16.696,16.696,16.696h107.824

    c9.22,0,16.696-7.475,16.696-16.696v-323.47C512,138.956,504.525,131.48,495.304,131.48z" />
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text">
                                    {{ $t('statistics') }}
                                </div>
                            </div>
                        </li>
                    </router-link>
                </div>
                <div class="asside-linker" v-if="['admin'].includes(role) || org_admins.includes(me.organization ? me.organization.id : me.organization)">
                    <router-link tag="div" class="asside-g-link" to="/report" active-class="router-link-svg" id="link_report" exact>
                        <li class="asside-linker-hover">
                            <div class="aside-icon">
                                <svg id="Capa_1" enable-background="new 0 0 512 512" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m106 512h300c24.814 0 45-20.186 45-45v-317h-105c-24.814 0-45-20.186-45-45v-105h-195c-24.814 0-45 20.186-45 45v422c0 24.814 20.186 45 45 45zm60-301h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h180c8.291 0 15 6.709 15 15s-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15s6.709-15 15-15zm0 60h120c8.291 0 15 6.709 15 15s-6.709 15-15 15h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15z" />
                                    <path d="m346 120h96.211l-111.211-111.211v96.211c0 8.276 6.724 15 15 15z" /></svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text">
                                    {{ $t('report') }}
                                </div>
                            </div>
                        </li>
                    </router-link>
                </div>
                <div class="asside-linker" v-if="['admin', 'ministry', 'org_admin', 'ministry_admin'].includes(role)">
                    <router-link tag="div" class="asside-g-link" :to="{ name: 'org_list' }" id="link_org" active-class="router-link-exact-active">
                        <li class="asside-linker-hover">
                            <div class="aside-icon">
                                <svg height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m328 224h32v32h-32zm0 0" />
                                    <path d="m328 304h32v32h-32zm0 0" />
                                    <path d="m128 272h32v32h-32zm0 0" />
                                    <path d="m208 112h32v32h-32zm0 0" />
                                    <path d="m128 32h32v32h-32zm0 0" />
                                    <path d="m128 192h32v32h-32zm0 0" />
                                    <path d="m208 32h32v32h-32zm0 0" />
                                    <path d="m128 112h32v32h-32zm0 0" />
                                    <path d="m328 384h32v32h-32zm0 0" />
                                    <path d="m248 384h32v32h-32zm0 0" />
                                    <path d="m248 224h32v32h-32zm0 0" />
                                    <path d="m248 304h32v32h-32zm0 0" />
                                    <path d="m400 448v-256h-192v256h-16v-264c0-4.417969 3.582031-8 8-8h80v-176h-192v448h-88v16h480v-16zm-88-232c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm-80-160c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm-40-352c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm-80-80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm0 80c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8v48c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8zm48 184v-72c0-4.417969-3.582031-8-8-8h-16c-4.417969 0-8 3.582031-8 8v72h-16v-72c0-13.253906 10.746094-24 24-24h16c13.253906 0 24 10.746094 24 24v72zm0 0" />
                                </svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text">
                                    {{ $t('organizations') }}
                                </div>
                            </div>
                        </li>
                    </router-link>
                </div>
                <div class="asside-linker" v-if="['operator', 'admin', 'ministry', 'operator', 'org_admin', 'ministry_admin'].includes(role)">
                    <div class="cover-asside-linker">
                        <router-link tag="div" to="/objects" class="asside-g-link" id="link_objects" active-class="router-link-exact-active">
                            <li class="asside-linker-hover">
                                <div class="aside-icon">
                                    <svg version="1.1" width="30" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 31.666 31.666" style="enable-background:new 0 0 31.666 31.666;" xml:space="preserve">
                                        <g>
                                            <path d="M11.452,31.666v-5.879h8.763v5.879h6.604V0H4.847v31.666H11.452z M20.215,2.909h3.696v3.696h-3.696V2.909z M20.215,8.282

    h3.696v3.697h-3.696V8.282z M20.215,13.656h3.696v3.695h-3.696V13.656z M20.215,19.028h3.696v3.698h-3.696V19.028z M13.986,2.909

    h3.697v3.696h-3.697V2.909z M13.986,8.282h3.697v3.697h-3.697V8.282z M13.986,13.656h3.697v3.695h-3.697V13.656z M13.986,19.028

    h3.697v3.698h-3.697V19.028z M7.757,2.909h3.696v3.696H7.757V2.909z M7.757,8.282h3.696v3.697H7.757V8.282z M7.757,13.656h3.696

    v3.695H7.757V13.656z M7.757,19.028h3.696v3.698H7.757V19.028z" />
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="aside-textes">
                                    <div class="aside-text flex justify-between align-center">
                                        {{ $t('objects') }}
                                    </div>
                                </div>
                            </li>
                        </router-link>
                    </div>
                </div>
                <div class="asside-linker" v-if="['operator', 'admin', 'ministry', 'org_admin', 'ministry_admin'].includes(role)">
                    <div class="cover-asside-linker">
                        <router-link tag="div" to="/building-list/" class="asside-g-link" id="link_building_list" active-class="router-link-exact-active">
                            <li class="asside-linker-hover">
                                <div class="aside-icon">
                                    <svg id="Layer_1" enable-background="new 0 0 511.999 511.999" height="30" viewBox="0 0 511.999 511.999" width="30" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="m476 120.004h-190v-110.004c0-5.522-4.477-10-10-10h-240c-5.523 0-10 4.478-10 10v491.999c0 5.522 4.477 10 10 10h240 200c5.523 0 10-4.478 10-10v-371.995c0-5.523-4.478-10-10-10zm-190 20h180v251.995h-180zm-160 351.995v-80h60v80zm70-100h-80c-5.523 0-10 4.478-10 10v90h-60v-471.999h220v471.999h-60v-90c0-5.522-4.478-10-10-10zm89.999 100v-80h180v80z" />
                                            <path d="m145.999 80.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 150.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 220.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 290h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 360h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 80.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 150.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 220.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 290h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 360h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m235.998 40.001h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10z" />
                                            <path d="m235.998 110h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10z" />
                                            <path d="m420.997 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m420.997 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m420.997 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.478-10-10-10z" />
                                            <path d="m350.999 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m350.999 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m350.999 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.478-10-10-10z" />
                                        </g>
                                    </svg>
                                </div>
                                <div class="aside-textes">
                                    <div class="aside-text flex justify-between align-center">
                                        {{ $t('buildings') }}
                                    </div>
                                </div>
                            </li>
                            <div v-if="['operator', 'admin', 'org_admin'].includes(role) && changed_building_count" class="notification_unattested_building">{{FilterNumber(changed_building_count)}}</div>
                        </router-link>
                    </div>
                </div>
                <div class="asside-linker" v-if="['operator', 'admin', 'org_admin', 'ministry', 'ministry_admin'].includes(role)">
                    <div class="cover-asside-linker">
                        <router-link tag="div" to="/unattested-building-list/" class="asside-g-link" id="link_unattested_building_list" active-class="router-link-exact-active">
                            <li class="asside-linker-hover">
                                <div class="aside-icon">
                                    <svg id="Layer_1" enable-background="new 0 0 511.999 511.999" height="30" viewBox="0 0 511.999 511.999" width="30" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="m476 120.004h-190v-110.004c0-5.522-4.477-10-10-10h-240c-5.523 0-10 4.478-10 10v491.999c0 5.522 4.477 10 10 10h240 200c5.523 0 10-4.478 10-10v-371.995c0-5.523-4.478-10-10-10zm-190 20h180v251.995h-180zm-160 351.995v-80h60v80zm70-100h-80c-5.523 0-10 4.478-10 10v90h-60v-471.999h220v471.999h-60v-90c0-5.522-4.478-10-10-10zm89.999 100v-80h180v80z" />
                                            <path d="m145.999 80.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 150.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 220.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 290h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 360h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 80.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 150.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 220.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 290h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 360h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m235.998 40.001h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10z" />
                                            <path d="m235.998 110h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10z" />
                                            <path d="m420.997 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m420.997 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m420.997 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.478-10-10-10z" />
                                            <path d="m350.999 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m350.999 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m350.999 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.478-10-10-10z" />
                                        </g>
                                    </svg>
                                </div>
                                <div class="aside-textes">
                                    <div class="aside-text flex justify-between align-center">
                                        {{ $t('unconfirmed_buildings') }}
                                    </div>
                                  
                                </div>
                            </li>
                            <div class="notification_unattested_building">{{FilterNumber(unapproved_buildings)}}</div>
                        </router-link>
                    </div>
                </div>
                <!-- <div class="asside-linker">
                    <div class="cover-asside-linker">
                        <div v-if="['operator', 'admin', 'ministry', 'operator'].includes(role)" class="asside-g-link" active-class="router-link-svg" exact>
                            <li @click="toggleMenu" class="asside-linker-hover">
                                <div class="aside-icon">
                                    <svg id="Layer_1" enable-background="new 0 0 511.999 511.999" height="30" viewBox="0 0 511.999 511.999" width="30" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="m476 120.004h-190v-110.004c0-5.522-4.477-10-10-10h-240c-5.523 0-10 4.478-10 10v491.999c0 5.522 4.477 10 10 10h240 200c5.523 0 10-4.478 10-10v-371.995c0-5.523-4.478-10-10-10zm-190 20h180v251.995h-180zm-160 351.995v-80h60v80zm70-100h-80c-5.523 0-10 4.478-10 10v90h-60v-471.999h220v471.999h-60v-90c0-5.522-4.478-10-10-10zm89.999 100v-80h180v80z" />
                                            <path d="m145.999 80.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 150.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 220.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 290h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m145.999 360h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 80.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 150.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 220.001h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 290h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m76.001 360h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10z" />
                                            <path d="m235.998 40.001h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10z" />
                                            <path d="m235.998 110h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.477-10-10-10z" />
                                            <path d="m235.998 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.477-10-10-10z" />
                                            <path d="m420.997 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m420.997 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m420.997 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.478-10-10-10z" />
                                            <path d="m350.999 180h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m350.999 250h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.522-4.478-10-10-10z" />
                                            <path d="m350.999 320h-19.997c-5.523 0-10 4.477-10 10v20c0 5.523 4.477 10 10 10h19.997c5.523 0 10-4.477 10-10v-20c0-5.523-4.478-10-10-10z" />
                                        </g>
                                    </svg>
                                </div>
                                <div class="aside-textes">
                                    <div class="aside-text flex justify-between align-center">
                                        Бино ва иншоотлар
                                        <i class="el-icon-arrow-down ml-auto t05" :class="toggle =='open'? 'r180': ''"></i>
                                    </div>
                                </div>
                            </li>
                        </div>
                        <div class="building2">
                            <router-link tag="div" to="/building-list-type/new" class="second-link" active-class="router-link-activer" exact>
                                Янги қурилган бино ва иншоотлар
                            </router-link>
                            <router-link tag="div" to="/building-list-type/exg" class="second-link" v-if="['admin', 'operator'].includes(role)" active-class="router-link-activer" exact>
                                Мавжуд бино ва иншоотлар
                            </router-link>
                        </div>
                        <el-collapse-transition>
                            <div class="buildings" v-show="toggle === 'open'">
                                <router-link tag="li" to="/building-list-type/new" class="second-link" active-class="router-link-activer" exact>Янги қурилган бино ва иншоотлар</router-link>
                                <router-link tag="li" to="/building-list-type/exg" class="second-link" v-if="['admin', 'operator'].includes(role)" active-class="router-link-activer" exact>
                                    Мавжуд бино ва иншоотлар
                                </router-link>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div> -->
                <!-- Suv omborlari -->
                <div class="asside-linker" v-if="['admin', 'reservoir'].includes(role)">
                    <router-link tag="div" class="asside-g-link" to="/reservoir" id="link_reservoir" active-class="router-link-exact-active">
                        <li class="asside-linker-hover">
                            <div class="aside-icon">
                                <svg version="1.1" id="Capa_1" height="30" width="30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 474.533 474.533;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M315.602,276.003c43.281,0.097,78.445-34.911,78.542-78.192c0.029-12.805-3.081-25.422-9.057-36.747L325.006,47.17

    c-2.515-5.194-8.765-7.365-13.959-4.849c-2.116,1.025-3.825,2.733-4.849,4.849l-60.082,113.894

    c-16.143,30.398-10.407,67.79,14.106,91.951C274.938,267.668,294.836,275.928,315.602,276.003z M264.925,170.468l50.678-96.131

    l50.677,96.131c14.968,27.988,4.413,62.811-23.576,77.779c-27.988,14.968-62.811,4.413-77.779-23.576

    C255.869,207.739,255.869,187.401,264.925,170.468z" />
                                                <path d="M108.19,140.166c12.608,0.082,24.697-5.018,33.437-14.106c15.188-14.736,18.808-37.736,8.882-56.424L117.072,5.897

    c-2.515-5.194-8.765-7.365-13.959-4.849c-2.116,1.025-3.825,2.733-4.849,4.849L64.827,69.636

    c-9.927,18.689-6.306,41.689,8.882,56.424C82.805,135.257,95.256,140.351,108.19,140.166z M83.635,79.04l24.033-45.975

    L131.7,79.04c5.665,10.667,3.762,23.776-4.702,32.392c-4.915,5.102-11.724,7.939-18.808,7.837

    c-7.389,0.088-14.517-2.725-19.853-7.837C79.711,102.896,77.792,89.675,83.635,79.04z" />
                                                <path d="M446.737,447.366c-6.617-4.599-12.44-10.246-17.241-16.718c-11.494-13.061-26.122-29.257-53.29-29.257

    c-27.167,0-41.273,15.674-53.29,29.78c-12.016,14.106-19.853,22.465-37.616,22.465c-17.763,0-26.645-9.927-37.616-22.465

    s-25.6-29.78-53.29-29.78c-27.69,0-41.273,15.674-53.29,29.78c-12.016,14.106-19.853,22.465-37.616,22.465

    c-17.763,0-26.645-9.927-37.616-22.465c-7.458-9.85-16.889-18.035-27.69-24.033c-5.194-2.597-11.509-0.492-14.106,4.702

    c-2.597,5.194-0.492,11.509,4.702,14.106c8.203,4.944,15.457,11.314,21.42,18.808c12.016,14.106,26.122,29.78,53.29,29.78

    c27.167,0,41.273-15.673,53.29-29.78c12.016-14.106,19.853-22.465,37.616-22.465c17.763,0,26.645,9.927,37.616,22.465

    c10.971,12.539,25.6,29.78,53.29,29.78c27.69,0,41.273-15.673,53.29-29.78c12.016-14.106,19.853-22.465,37.616-22.465

    s26.645,9.927,37.616,22.465c6.089,7.818,13.308,14.685,21.42,20.375c4.905,3.174,11.455,1.771,14.629-3.135

    C453.046,457.09,451.642,450.54,446.737,447.366z" />
                                                <path d="M28.778,337.129c8.203,4.945,15.457,11.314,21.42,18.808c12.016,14.106,26.122,29.78,53.29,29.78

    c27.167,0,41.273-15.673,53.29-29.78c12.016-14.106,19.853-22.465,37.616-22.465c17.763,0,26.645,9.927,37.616,22.465

    c10.971,12.539,25.6,29.78,53.29,29.78c27.69,0,41.273-15.673,53.29-29.78c12.016-14.106,19.853-22.465,37.616-22.465

    s26.645,9.927,37.616,22.465c6.089,7.818,13.308,14.685,21.42,20.376c4.905,3.174,11.455,1.77,14.629-3.135

    c3.174-4.905,1.771-11.455-3.135-14.629c-6.617-4.599-12.44-10.246-17.241-16.718c-11.494-13.061-26.122-29.257-53.29-29.257

    c-27.167,0-41.273,15.673-53.29,29.78c-12.016,14.106-19.853,22.465-37.616,22.465c-17.763,0-26.645-9.927-37.616-22.465

    c-10.971-12.539-25.6-29.78-53.29-29.78c-27.69,0-41.273,15.673-53.29,29.78c-12.016,14.106-19.853,22.465-37.616,22.465

    c-17.763,0-26.645-9.927-37.616-22.465c-7.458-9.849-16.889-18.035-27.69-24.033c-5.194-2.597-11.509-0.492-14.106,4.702

    C21.479,328.217,23.584,334.533,28.778,337.129z" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text">
                                    {{ $t('reservoirs') }}
                                </div>
                            </div>
                        </li>
                    </router-link>
                </div>
                <div class="asside-linker" v-if="[ 'admin', 'org_admin', 'tf_operator'].includes(role)">
                    <router-link tag="div" class="asside-g-link" to="/transport" id="link_transport" active-class="router-link-exact-active">
                        <li class="asside-linker-hover">
                            <div class="aside-icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 19.35V13.65L9.4 9.65C9.48333 9.46667 9.604 9.31267 9.762 9.188C9.92067 9.06267 10.1167 9 10.35 9H17.65C17.8833 9 18.0793 9.06267 18.238 9.188C18.396 9.31267 18.5167 9.46667 18.6 9.65L20 13.65V19.35C20 19.5333 19.9373 19.6873 19.812 19.812C19.6873 19.9373 19.5333 20 19.35 20H18.65C18.4667 20 18.3127 19.9373 18.188 19.812C18.0627 19.6873 18 19.5333 18 19.35V18.5H10V19.35C10 19.5333 9.93733 19.6873 9.812 19.812C9.68733 19.9373 9.53333 20 9.35 20H8.65C8.46667 20 8.31267 19.9373 8.188 19.812C8.06267 19.6873 8 19.5333 8 19.35ZM10 12.5H18L17.3 10.5H10.7L10 12.5ZM11 16.5C11.2833 16.5 11.521 16.404 11.713 16.212C11.9043 16.0207 12 15.7833 12 15.5C12 15.2167 11.9043 14.9793 11.713 14.788C11.521 14.596 11.2833 14.5 11 14.5C10.7167 14.5 10.4793 14.596 10.288 14.788C10.096 14.9793 10 15.2167 10 15.5C10 15.7833 10.096 16.0207 10.288 16.212C10.4793 16.404 10.7167 16.5 11 16.5ZM17 16.5C17.2833 16.5 17.5207 16.404 17.712 16.212C17.904 16.0207 18 15.7833 18 15.5C18 15.2167 17.904 14.9793 17.712 14.788C17.5207 14.596 17.2833 14.5 17 14.5C16.7167 14.5 16.4793 14.596 16.288 14.788C16.096 14.9793 16 15.2167 16 15.5C16 15.7833 16.096 16.0207 16.288 16.212C16.4793 16.404 16.7167 16.5 17 16.5ZM4 12V10H6V12H4ZM9 6V4H11V6H9ZM4 16V14H6V16H4ZM4 20V18H6V20H4ZM0 20V6H5V0H15V7H13V2H7V8H2V20H0Z" />
                                </svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text">
                                    {{ $t('transport_facilities') }}
                                </div>
                            </div>
                        </li>
                    </router-link>
                </div>
                <div class="asside-linker" v-if="['operator',  'admin', 'org_admin'].includes(role)">
                        <router-link tag="div" class="asside-g-link" to="/investment/3" id="link_transport" active-class="router-link-exact-active">
                            <li class="asside-linker-hover">
                                <div class="aside-icon">
                                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1902_890)">
                                      <path d="M25 21.875L20.3125 25V23.4375H12.8906L16.0156 20.3125H20.3125V18.75L25 21.875ZM0 3.125L4.6875 0V1.5625H12.1094L8.98438 4.6875H4.6875V6.25L0 3.125ZM15.2187 0L0 15.2187L9.78125 25L25 9.78125L15.2187 0ZM2.17187 15.2187L15.2187 2.17187L22.8281 9.78125L9.78125 22.8281L2.17187 15.2187Z"/>
                                      <path d="M6.51758 15.2187L5.42383 16.3125L8.68945 19.5781L9.7832 18.4844L10.9395 19.5625L15.2207 15.2187C14.4961 15.9223 13.5237 16.3125 12.5137 16.3051C11.5037 16.2976 10.5372 15.8931 9.82306 15.1789C9.10888 14.4647 8.70436 13.4982 8.6969 12.4882C8.68944 11.4783 9.07965 10.5059 9.7832 9.78125L5.43945 14.0625L6.51758 15.2187Z"/>
                                      <path d="M19.5625 8.70313L16.2969 5.4375L15.2031 6.53125L14.0625 5.4375L9.78125 9.78125C10.5059 9.0777 11.4783 8.68749 12.4882 8.69495C13.4982 8.70241 14.4647 9.10693 15.1789 9.82111C15.8931 10.5353 16.2976 11.5018 16.3051 12.5118C16.3125 13.5217 15.9223 14.4941 15.2187 15.2188L19.5625 10.9375L18.4687 9.84375L19.5625 8.70313Z"/>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1902_890">
                                        <rect width="25" height="25" fill="white"/>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <div class="aside-textes">
                                    <div class="aside-text">
                                        {{ $t('investment_project') }}
                                    </div>
                                </div>
                            </li>
                        </router-link>
                    </div>
                <div class="asside-linker" v-if="['admin'].includes(role)">
                    <router-link tag="div" class="asside-g-link" to="/users" id="link_users" active-class="router-link-exact-active">
                        <li class="asside-linker-hover">
                            <div class="aside-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 640 512">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z" /></svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text">
                                    {{ $t('users') }}
                                </div>
                            </div>
                        </li>
                    </router-link>
                </div>
            </ul>
        </div>
            <div class="bottom-asside" :class="AppAsside ? 'bottom-asside-active': ''">

                <div class="asside-linker" style="width:100%;">
                    <div>
                        <div class="flex items-center asside-telegram">
                            <div>
                                <a href="https://t.me/joinchat/QnczmsV4ANA1OTgy">
                                    <svg enable-background="new 0 0 24 24" height="26" width="26" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" fill="#039be5" r="12" />
                                        <path d="m5.491 11.74 11.57-4.461c.537-.194 1.006.131.832.943l.001-.001-1.97 9.281c-.146.658-.537.818-1.084.508l-3-2.211-1.447 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.333-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953z" fill="#fff" /></svg>
                                </a>
                            </div>
                            <div>
                                <a href="#">
                                    <svg version="1.1" id="Capa_1" height="26" width="26" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 112.196 112.196" style="enable-background:new 0 0 112.196 112.196;" xml:space="preserve">
                                        <g>
                                            <circle style="fill:#3B5998;" cx="56.098" cy="56.098" r="56.098" />
                                            <path style="fill:#FFFFFF;" d="M70.201,58.294h-10.01v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34

    c0-5.964,2.833-15.303,15.301-15.303L71.56,21.81v12.51h-8.151c-1.337,0-3.217,0.668-3.217,3.513v7.585h11.334L70.201,58.294z" />
                                        </g>
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-aside" style="margin:10px 0px;">
                    <div></div>
                </div>
                <div class="asside-linker" style="width:100%;">
                    <router-link tag="div" to="/about" class="asside-g-link" id="link_about" exact>
                        <button class="asside-linker-button asside-linker-hover">
                            <div class="aside-icon">
                                <svg version="1.1" id="Capa_1" width="20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <path d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256

    C512,114.497,397.492,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216

    c119.393,0,216,96.615,216,216C472,375.393,375.384,472,256,472z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M256,214.33c-11.046,0-20,8.954-20,20v128.793c0,11.046,8.954,20,20,20s20-8.955,20-20.001V234.33

    C276,223.284,267.046,214.33,256,214.33z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <circle cx="256" cy="162.84" r="27" />
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text flex justify-between align-center ">
                                    {{ $t('about') }}
                                </div>
                            </div>
                        </button>
                    </router-link>
                </div>
                <div class="asside-linker" style="width:100%;">
                    <router-link tag="div" to="/help" class="asside-g-link" id="link_help" active-class="router-link-svg" exact>
                        <button class="asside-linker-button asside-linker-hover">
                            <div class="aside-icon">
                                <svg version="1.1" id="Capa_1" width="20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <g>
                                                <circle cx="256" cy="378.5" r="25" />
                                                <path d="M256,0C114.516,0,0,114.497,0,256c0,141.484,114.497,256,256,256c141.484,0,256-114.497,256-256

    C512,114.516,397.503,0,256,0z M256,472c-119.377,0-216-96.607-216-216c0-119.377,96.607-216,216-216

    c119.377,0,216,96.607,216,216C472,375.377,375.393,472,256,472z" />
                                                <path d="M256,128.5c-44.112,0-80,35.888-80,80c0,11.046,8.954,20,20,20s20-8.954,20-20c0-22.056,17.944-40,40-40

    c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40c-11.046,0-20,8.954-20,20v50c0,11.046,8.954,20,20,20

    c11.046,0,20-8.954,20-20v-32.531c34.466-8.903,60-40.26,60-77.469C336,164.388,300.112,128.5,256,128.5z" />
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text flex justify-between align-center ">
                                    {{ $t('help') }}
                                </div>
                            </div>
                        </button>
                    </router-link>
                </div>
                <!-- <div class="asside-linker" style="width:100%;">
                    <div @click="logout()" class="asside-g-link" active-class="router-link-svg" id="logout">
                        <button class="asside-linker-button asside-linker-hover">
                            <div class="aside-icon flex justify-center">
                                <svg version="1.1" width="20" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 489.9 489.9" style="enable-background:new 0 0 489.9 489.9;" xml:space="preserve">
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M468.3,255.8c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.4,0.6-0.7,0.8-1.1c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.4,0.5-0.8,0.7-1.2

    c0-0.1,0.1-0.2,0.1-0.2c0.2-0.4,0.4-0.8,0.6-1.3c0-0.1,0-0.1,0.1-0.2c0.2-0.4,0.3-0.9,0.5-1.4c0-0.1,0-0.2,0.1-0.2

    c0.1-0.5,0.3-0.9,0.3-1.4c0-0.2,0-0.3,0.1-0.5c0.1-0.4,0.1-0.8,0.2-1.2c0.1-0.6,0.1-1.1,0.1-1.7c0-0.6,0-1.1-0.1-1.7

    c0-0.4-0.1-0.8-0.2-1.2c0-0.2,0-0.3-0.1-0.5c-0.1-0.5-0.2-0.9-0.3-1.4c0-0.1,0-0.2-0.1-0.2c-0.1-0.5-0.3-0.9-0.5-1.4

    c0-0.1,0-0.1-0.1-0.2c-0.2-0.4-0.4-0.9-0.6-1.3c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.4-0.4-0.8-0.7-1.2c-0.1-0.1-0.1-0.2-0.2-0.3

    c-0.3-0.4-0.5-0.8-0.8-1.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.4-0.4-0.7-0.9-1.2-1.3l-98.9-98.8c-6.7-6.7-17.6-6.7-24.3,0

    c-6.7,6.7-6.7,17.6,0,24.3l69.6,69.6H136.8c-9.5,0-17.2,7.7-17.2,17.1c0,9.5,7.7,17.2,17.2,17.2h276.8l-69.1,69.1

    c-6.7,6.7-6.7,17.6,0,24.3c3.3,3.3,7.7,5,12.1,5s8.8-1.7,12.1-5l98.3-98.3C467.5,256.6,467.9,256.2,468.3,255.8z" />
                                                <path d="M110.7,34.3h128c9.5,0,17.2-7.7,17.2-17.1c0-9.5-7.7-17.2-17.2-17.2h-128C59.4,0,17.6,41.8,17.6,93.1v303.7

    c0,51.3,41.8,93.1,93.1,93.1h125.9c9.5,0,17.2-7.7,17.2-17.1c0-9.5-7.7-17.2-17.2-17.2H110.7c-32.4,0-58.8-26.4-58.8-58.8V93.1

    C52,60.6,78.3,34.3,110.7,34.3z" />
                                            </g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>
                            <div class="aside-textes">
                                <div class="aside-text flex justify-between align-center ">
                                    {{ $t('logout') }}
                                </div>
                            </div>
                        </button>
                    </div>
                </div> -->
                <!-- <div style="width:100%; overflow: hidden;">
                    <div class="border-aside" style="margin:10px 0px;">
                        <div></div>
                    </div>
                    <div class="asside-linker " style="width:100%;">
                        <div class="asside-g-link">
                            <button class="asside-linker-button " @click="AppAsside=!AppAsside, toggle ='close', toggleHelp=false">

                                <div class="aside-textes">
                                    <div class="aside-text" style="text-align:left; padding:0px 10px;">
                                        {{ $t('close') }}

                                    </div>
                                </div>
                                <div class="aside-icon">
                                    <svg :class="!AppAsside ? 'r180': ''" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 16 16">
                                        <path d="M7.7 3.7L3.4 8l4.3 4.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-5-5c-.4-.4-.4-1 0-1.4l5-5c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4zm7 0L10.4 8l4.3 4.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0l-5-5c-.4-.4-.4-1 0-1.4l5-5c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z" />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </div> -->

                <!-- <button @click="AppAsside=!AppAsside">
                    <svg version="1.1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 16 16">
                        <path fill="#444444" d="M13.1 8l-8 8-2.2-2.1 5.9-5.9-5.9-5.9 2.2-2.1z"></path>
                    </svg>
                </button> -->
            </div>
        </div>
    </div>
    </template>

    <script> 

    export default {
        data() {
            return {
                catlist: "",
                toggle: "close",
                togglesuv: 'close',
                AppAsside: true,
                toggleHelp: false,
                org_admins: [2675, 3737, 8209, 4061],
                changed_building_count:0
            };
        },
        computed: {
            unapproved_buildings() {
              return this.$store.state.unapproved_buildings
            },
            role() {
                return localStorage.role;
            },
            me() {
                return this.$store.state.me;
            },
            sidebarToggle(){
            return this.$store.state.sidebarToggle
        }
        },
        methods: {
            toggleMenu() {
                if (this.AppAsside) {
                    if (this.toggle == "open") this.toggle = "close";
                    else this.toggle = "open";
                }
            },
            toggleMenuSuvombor() {
                if (this.AppAsside) {
                    if (this.togglesuv == "open") this.togglesuv = "close";
                    else this.togglesuv = "open";
                }
            },
            handleOpen() {},
            logout() {
                localStorage.token = '';
                localStorage.refresh = '';
                localStorage.role = '';
                this.$store.commit('get_me', '');
                this.$router.push('/login')
                    .catch(e => {
                        if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                            console.log(e)
                        }
                    });
            },
            getChangedBuildingcount(){
                if([ 'admin', 'org_admin', 'operator'].includes(this.role)){
                    axios.get(`/building/specificationlist/`, {params:{ type:'new' }})
                    .then(res=>{
                        this.changed_building_count=res.data.count
                    })
                }  
            },
            FilterNumber(e){
                if(e && e>10){
                    return '10+'
                }
                else{
                    return e
                }
            }
        },
        mounted(){
            this.getChangedBuildingcount()
        }
    };
    </script>

    <style lang="scss" scoped>
    .appAside {
        position: relative;
        min-height: 100vh;
        transition: all .2s linear;
        width: 70px;
        z-index: 11;
        top: 0;
        left: 0;

        // box-shadow: -5px 5px 5px rgba(221, 221, 221, 0.2), 5px -5px 10px rgba(221, 221, 221, 0.2), -5px -5px 5px rgba(255, 255, 255, 0.1), 5px 5px 13px rgba(221, 221, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.1), inset -1px -1px 2px rgba(221, 221, 221, 0.1);
        .routes {
            padding: 10px 0;
            margin: 0;
            list-style: none;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .buildings {
                padding: 0px 1px 0px 40px;
                width: 100%;
                display: flex;
                align-content: flex-end;
                flex-direction: column;
                transition: all .2s ease-in;
                overflow-x: hidden;

                li {
                    width: 100%;
                    margin-top: 3px;
                }
            }

            a {
                width: 100%;
                padding: 0 10px;
                margin: 0;
                max-width: 250px;
                overflow: hidden;
            }

            .router-link-exact-active {
                li {
                    background: #ffb800 !important;
                    color: #004787;
                    svg {
                        fill: #004787;
                    }

                }

                button {
                    background: #ffb800 !important;
                    fill: #004787;

                    svg {
                        fill: #004787;
                    }
                }
            }

            .router-link-active {
                li {
                    color: #004787;
                    background: #ffb800 !important;
                    svg {
                        fill: #004787;
                    }
                }

                button {
                    background: #ffb800 !important;
                    fill: #004787;

                    svg {
                        fill: #004787;
                    }
                }
            }

            .router-link-activer {
                 background: #ffb800 !important;
                color: #004787 !important; 
                svg {
                    color: #004787 !important;
                }
            }

            button {
                border-radius: 16px;
            }

            li {
                width: 100%;
                cursor: pointer;
                user-select: none;
                min-height: 45px;
                padding: 0 0px;
                margin: 5px auto;
                background: #ffffff;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: #004787; 
                overflow: hidden;
                border-radius: 15px !important;

                i {
                    margin-left: 10px;
                    font-weight: 800;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                }

                svg {
                    fill: #004787;
                    height: 18px;
                    min-width: 22px;
                }
            }
        }
    }

    .router-link-exact-active {

        button {
            background-color: #ffb800 !important;
            color: #004787;

            svg {
                fill: #004787 !important;
            }
        }
    }

    .cover-asside-linker {
        border-radius: 16px;
        background: #fff;
    }

    .appAside-activer {
        width: 280px;
    }

    .appAside-activer .aside-textes {
        overflow: hidden;
        max-width: 260px;
        text-align: left;
    }

    .aside-icon {
        padding: 0px 10px;
        width: 50px;
        margin-right: 0px;
        display: flex;
        justify-content: center;
    }

    .aside-icon svg {
        margin: 0 !important;
        fill: #004787;
    }

    .aside-text {
        min-width: 210px;
        width: 210px;
        padding-right: 10px;
        text-align: left;
    }

    .aside-textes {
        overflow: hidden;
        max-width: 0;
        transition: max-width 0.2s linear;

    }
    .notification_unattested_building{
          position: absolute;
          top: 4px;
          right: 4px;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: white;
          font-size: 10px;
          background: #ff4400 !important;
        }

    .router-link-svg svg {
        fill: #004787 !important;
    }

    .aside-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0; 
        width: inherit;
        background: #004787;
        border-radius: 0px 100px 0px 0px;
        box-shadow: 0 0 8px #ffb800; 
        overflow: auto;
    }
    .bottom-asside {
        padding: 10px 0px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

    }

    .bottom-asside .close-asside {
        transform: rotate(180deg);
        outline: none;
    }

    .bottom-asside .close-asside svg {
        fill: #004787;
    }

    .bottom-asside-active .close-asside {
        transform: rotate(0deg);
        background-color: #004787 !important
    }

    .bottom-asside-active .close-asside svg {
        fill: #004787 !important;
    }

    .asside-linker-button {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        user-select: none;
        min-height: 45px;
        padding: 0;
        margin: 5px auto;
        background: #ffffff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #004787;
        overflow: hidden;
        outline: none !important;
        border-radius: 20px;
    }
    .asside-linker-hover{
        position: relative;
    }
    .asside-linker-hover:hover {
        // background: #ffb800 !important;  
        &::before{
            content: ' ';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px;
            height: 100%;
            width: 8px;
            background: #ffb800;

        }
        color:#004787 !important;

        svg {
            fill: #004787 !important;
        }
    }

    .asside-linker-button svg {
        margin-left: 2px !important;
    }

    .asside-linker-hover:hover svg {
        fill: #004787;
    }

    .asside-linker-hover:hover {
        color: #004787;
    }

    .bottom-link {
        padding: 10px;
        border-radius: 10px;
        width: 45px;
        min-width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none !important;
    }

    .bottom-link svg {
        fill: #004787;
    }

    .second-link {
        padding-left: 10px !important;
    }

    .asside-linker {
        position: relative;
        padding: 0px 10px 0px 10px;
        width: 100%;
    }

    .closen-assider .asside-linker:hover .building2 {
        display: block;
    }

    .building2 {
        position: absolute;
        width: 400px;
        top: 2px;
        left: 72px;
        background: #fff;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
        padding: 10px 0px;
        display: none;
        border-radius: 10px;
        z-index: 10;

    }

    .building2 .second-link {
        width: 100%;
        padding: 10px 15px;
        cursor: pointer;
        position: relative;

    }

    .second-link:hover {
        color: #2c525a !important;
        font-weight: 600;
    }

    .second-link:hover svg {
        fill: #004787 !important;
    }

    .building2 .second-link div {
        position: absolute;
    }

    .border-aside {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .border-aside div {
        width: 85%;
        background: #004787;
        height: 1px;
    }

    .bottom-asside .buildings {
        padding: 1px 1px 1px 40px;
        width: 100%;
        display: flex;
        align-content: flex-end;
        flex-direction: column;
        transition: all .1s ease-in;
        overflow-x: hidden;

        button {
            width: 100%;
            margin-top: 3px;
            padding: 10px;
            border-radius: 6px;
            text-align: left;
        }
    }

    .dd-icon {
        margin-left: 10px;
        font-weight: 800;
        font-size: 19px;
        display: flex;
        align-items: center;
    }

    .r180 {
        transform: rotate(180deg);
    }

    .t05 {
        transition: all .2s ease-in;
    }

    .asside-telegram {
        padding: 0px 8px;
        overflow: hidden;
        flex-wrap: wrap;
    }

    .asside-telegram div {
        padding: 4px 4px;
    }

    .asside-header{
        width: 100%; 
        padding: 10px;
        display: flex; 
        align-items: center;
        padding-top: 40px;
        flex-direction: column;
        gap: 16px;
        .asside-header-icon{
        
            width: 50px;
            min-width: 50px;
            img{
                width: 100%;
            }
        }
        .asside-header-content{
            flex: 1;
            overflow: hidden;
        }
        .asside-header-text{
            color: #fff;
            width: 260px;
            font-weight: 700;
            font-size: 12px;
            text-align: center;
        }


    }

    @media screen and (min-width: 2000px) {
        .aside-content{
            top: 0;
            left: auto;
        }
    }
    </style>
