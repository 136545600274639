<template> 
<div class="w-full flex justify-center">
    <div class="f-container">
<div class="default" v-if="$route.name!='check' && !tech_works">
    <div class="wrapper" style="padding: 0" v-if="$route.name === 'login' || !first ">
        <div v-if="config" class="d-flex"  >
            <AppAside v-if="!hideBars" />

            <div style="flex: 1; overflow: hidden;" class="router-content">
                <AppNavbar v-if="!hideBars && !first && config" />
            <router-view />
            <div v-if="!hideBars" class="app-footer-container">
                <div class="nav-bottom">
                <div class="nav-bottom-div"><p> <b> {{$t('fron_version')}}:</b> {{$store.state.version}}  <b>/</b> <b>{{$t('back_version')}} :</b> {{$store.state.back_ver}} </p></div>
                <div class="flex items-center nav-bottom-div">
                    <div class="flex items-center">
                        <a href="https://unicon.uz/"> <img width="110" src="@/assets/img/unicon.png" alt=""></a>
                    </div>
                </div>
            </div>
            </div>
          
            </div>
        </div>
        <div class="config-cadaster" v-else>
            <div class="cadastre-scroll">
                <div class="config-cadaster-item">
                    <h1 class="title-config">Ҳурматли фойдаланувчи!</h1>

                    <table class="w-full">
                        <tr v-for="(item, i) in configData" :key="i" class="config-text">
                            <td><span class="c-red">{{item.cadastral_number}}</span></td>
                            <td>кадастр рақамли объект учун</td>
                            <td><span class="c-red">{{item.number_buildings}}</span> </td>
                            <td>- та бино</td>
                        </tr>
                    </table>
                    <p class="config-text2"> Мазкур объектларга тегишли биноларни тизимга киритишингиз зарур. Илтимос бинолар хақида маьлумотларни тизимга тўлик киритинг. </p>
                    <div class="w-full flex justify-center">
                        <button @click="config=true, $router.push('/objects')" class="config-button">Тўлдириш</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <First v-else />
</div>
<div class="check-layout" v-else-if="!tech_works">
    <router-view />
</div>
<div v-else>
    <TechniqueWorks />
</div>
</div>
</div>

</template>

<script>
import First from './components/First.vue';
import TechniqueWorks from './components/TechniqueWorks.vue';
import axios from 'axios';
export default {
    components: {
        First,
        TechniqueWorks
    },
    data() {
        return {
            now_day: '',
            now_time: '',
            back_ver:'1.0.0',
            obj: {
                profile_for_user: {}
            },
            configData: [],
            config: true,
            hideBars: true,
            tech_works:false
        };
    },
    computed: {

        me() {
            return this.$store.state.me
        },
        first() {
            return this.$store.state.first
        },
        role() {
            return this.$store.state.role;
        },
    },
    watch: {
      '$route.path': function() {
        this.$store.dispatch("get_unapproved_buildings")
      },
        role() {
            if (this.role == 'operator' && this.$route.name != 'login') {
                axios.get('/building/verificationobjects/')
                    .then(res => {
                        this.configData = res.data
                        if (res.data.length > 0) {
                            this.config = false
                        } else {
                            this.config = true

                        }
                    })
            } else {
                this.config = true
            }

            if (this.$route.name == 'login') {
                this.config = true
            }

        },
        '$route.name'() {
            if (this.$route.name != "login") {
                this.hideBars = false
                if(this.$route.name=='object' ){
                    this.config = true
                }
            }
            else {
                this.hideBars = true
                this.config = true

            }
        },

    },
    mounted() {
        if(localStorage.token) axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;
        // if(this.$route.name!='login' && localStorage.token!=null){ this.$store.dispatch("get_me")}
        axios.get('', {headers:{Authorization:[]}})
        .then(res=>{
            if(res && res.data && res.data.version){
                this.back_ver=res.data.version
                this.$store.commit('set_back_ver', res.data.version)
            }
        })
        setInterval(()=> {
            var date = new Date
            this.now_day = date.getFullYear() + "." + ((date.getMonth() + 1)>9?(date.getMonth() + 1):'0'+(date.getMonth() + 1)) + "." + (date.getDate()>9?date.getDate():'0'+date.getDate())
            this.now_time =date.getHours() + ":" + (date.getMinutes()>9? date.getMinutes(): '0'+date.getMinutes()) + ":" +( date.getSeconds()>9?date.getSeconds():'0'+date.getSeconds())

        }, 1000);
    },

    updated() {
        if (!localStorage.token && this.$route.name !== "login" && this.$route.name !== "check") {
            this.$router.push("/?redirect=" + this.$route.path);
        }
    },
};
</script>

<style lang="scss">
.default{width: 100%; }
.f-container{
    width: 100%;
    max-width: 2000px; 
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

.login-one-id{
    width: 100%;
    display: flex;
    justify-content: center;
    button{
        padding: 0px 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        background: #4630C2;
        img{
            width: 100px;
        }
        i{
            color: #fff !important;
            margin-left: 10px;
            font-size: 16px !important;
            font-weight: 600 !important;

        }
        &:hover{
          i{
            animation-name: animeLogin;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in;

        }
        }
    }
}

@keyframes animeLogin{
    from{
        transform: translateX(0px);
    }
    from{
        transform: translateX(-10px);
    }
}

.swal2-container{
    z-index: 3000 !important;
}

.router-link{
    text-decoration: underline !important;
    font-weight: 600 !important;
    cursor: pointer;
    i{
        font-weight: 600;
    }
}

.fs-10 .el-form-item__error {
    font-size: 10px;
}

.is-valid input {
    border: 1px solid red !important;
}

.error-span {
    color: red;
    font-size: 12px !important;
}

.router-item {
    overflow-y: scroll !important;
    min-height: 80vh;
}

.pt80 {
    padding: 80px 0px 0px 0px !important;
}

body {
    padding: 0;
    margin: 0;
    background: white;
}

.cp {
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

* {
    font-family: Montserrat;
}

.d-flex {
    display: flex;
}

.h-40 {
    height: 40px;
    max-height: 40px;
}
.default-button{
    padding: 5px 0px !important;
    img{
        width: 100%;
    }
}
.w-40px{
    width: 40%;
}
.noborder{ border: none !important;}
.wrapper {
    padding: 20px;
    flex: 1;
    background-image: #fff;
}
.el-form-item.is-error{
  .el-input__inner{
    border-color: #F56C6C !important;
  }
}
.el-input__inner{
    color: #004787 !important;
    border: 1px solid #fff !important;
    border-radius: 16px !important;
    font-weight: 600 !important; 
    box-shadow: 0 4px 8px 2px rgba(0,0,0,0.2);
}


.el-input.is-disabled .el-input__inner {
    background-color: #eaeaea!important;
    border: 1px solid #eaeaea !important;
    color: #004787 !important;
    cursor: not-allowed;
}
.border-radius-20 {
    border-radius: 20px !important;
}
.border-radius{
    .el-input__inner{
        border-radius: 10px !important;
    }
}
.actions {
    margin-top: 40px;

    .el-button {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }

    .primary {
        display: flex;
        justify-content: space-between;

        .el-button {
            width: calc(50% - 7.5px);
        }
    }
}

.wp-body {
    // background: #ffffff;
    // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    // padding: 17px;
    overflow: hidden;
    /* padding-bottom: 40px; */
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.color-primary{
    color:  #004787 !important;
}

.new_build {
    background-color: #004787 !important;
    color: #fff !important;
}

.exist_build {
    background-color: #fff !important;
    color: #004787 !important;
}

.primary-btn {
    background: #004787 !important;
    color: #fff !important;
    font-weight: 600;
}
.dark-btn {
      background: #000 !important;
    color: #fff !important;
    font-weight: 600;
}

.green-btn {
    background: rgb(20, 134, 20) !important;
    color: #fff !important;
}

.obj-name {
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    padding-bottom: 10px;
    color: #004787;
}

.wp-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    padding: 5px;
    padding-bottom: 20px;
 

    .right {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .search {
        max-width: 600px;
        margin-left: 16px;
    }
}

.mw-100 {
    min-width: 100px !important;
    flex: 1
}

.mTitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #004787;
}

.mTitle2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    color: #004787;
}

.input-section {
    font-family: Montserrat;
    color: #004787;

    .item {
        display: block;
        margin-top: 18px;

        p {
            font-size: 16px;
            margin-bottom: 3px;
        }
    }
}

.build-num {
    width: 40px;
    height: 40px;
    margin-left: 18px;
    display: flex;
    background-color: #004787;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}
.el-alert{
    border-radius: 15px !important;
}
.text-area {
    textarea {
        height: 125px;
    }
}
.text-left{
    text-align: left !important;
}
.el-button{
    border-radius: 15px !important;
}
.el-button-download{
    border-radius: 10px !important;
    height: 30px !important;
    width: 30px !important;
    border: 1px solid #004787 !important;
    border-radius: 50% !important;
    color: #004787 !important;
    font-weight: 600 !important;
    padding: 5px !important;
    i{
        color: #004787;
        font-weight: 600 !important;
    }
    &::before{
        color: #004787;
    }

    &.danger{
        color: #FF3838;
        border: 1px solid #FF3838 !important;
        &::before{
            color: #FF3838 !important;
        }

    }

}

.back-button {
    padding: 10px 20px;
    font-weight: 600;
    background: #000;
    color: #fff;
    border-radius: 15px;
    font-size: 16px;
}
.is-disabled{
    opacity: 0.7 !important;
}
.wp-table {
    border-collapse: collapse;
    margin-top: 20px;
    width: 100%;

    thead {
        background-color: #eaeaea;
    }

    tbody tr td:last-child {
        .d-flex {
            justify-content: flex-end;
        }
    }

    td {
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid #d4d4d4;
    }
}

.el-pagination {
    padding-top: 15px !important;
}
.el-pager li {
    background: linear-gradient(135deg, #F2F2F2 0%, #FFFFFF 100%) !important;
    color: #004787 !important;
    border-radius: 10px !important;
    border: 1px solid rgba(25, 152, 170, 0.2) !important;
    font-size: 12px !important;
}
.el-pager li.active{
    background: #004787 !important;
    color: #FFF !important;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
    border-radius: 10px !important;
}

.el-checkbox__inner{
    width: 20px !important;
    height: 20px !important;
    border-radius: 5px !important;
    padding: 4px !important;
    font-size: 16px !important;
    border: 1px solid #004787 !important;
    background: linear-gradient(135deg, #F2F2F2 0%, #FFFFFF 100%) !important;
    }
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background:  #004787 !important;
}


.el-checkbox__inner::after{
    width: 4px !important;
    height: 8px !important;
    top: 3px !important;
    left: 5px !important;
    font-weight: 600 !important;
}

.el-breadcrumb {
    // font-size: 16px !important;
    color: #004787 !important;
    line-height: 1;
}

.el-table thead,
th {
    color: #00363E !important;
    background-color: #ffff !important;
    font-weight: 600;
}

.el-radio {
    color: #606266 !important;
    cursor: pointer;
    margin-right: 0 !important;
    border-radius: 15px;
}
.el-radio.is-bordered {
    padding: 10px 20px 0 10px !important;
    border-radius: 15px !important; 
}

.el-radio.is-bordered.is-checked {
    border-color: #004787 !important;
    color: #fff !important;
    background: #004787;
}

.prim .el-radio__input.is-checked+.el-radio__label {
    color: #fff !important;
    font-size: 16px;
    padding-top: 2px !important;
}

.prim .el-radio__input.is-checked .el-radio__inner {
    border-color: #004787 !important;
    background: #fff !important;
}
.el-radio__inner{
    width: 18px !important;
    height: 18px !important;
    background: #fff !important;
}
.el-radio__inner::after{
    width: 8px !important;
    height: 8px !important;
    background: #004787 !important;
}

.el-pagination .el-select .el-input {
    width: 150px !important;
    margin: 0 5px;
}

// .final {
//   .el-input__inner {
//     border-color: #67C23A;
//     color:#67C23A;
//   }
// }
.el-table__expand-icon {
    margin-right: 10px !important;

}

.el-icon-arrow-right {
    color: blue !important;
    font-size: 14px !important;
    font-weight: 600;
}

// fixed

.fixed-item {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(30px);
}

.fixed-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.8;
    z-index: 0;
}

.pr {
    position: relative;
}

.kt-button {
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: 600;
}

.error input {
    border-color: red !important;
    color: red;
}

.error span::after {
    color: red !important;
}

.connecting input {
    border-color: yellow !important;
}

.succes input {
    border-color: rgb(101, 236, 101) !important;
    color: green;
}

.about-knumber {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.about-knumber button {
    outline: none !important;
}

.k-input {
    font-size: 18px !important;
}

.k-input-paret{
    display: flex;
    align-items: center;
    gap: 4px;
}


.kadaster-item {
    max-width: 600px;
}

.border-red input {
    border-color: red;
    color: red;
    outline: none;
}

.erorrsk {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    color: #fff;
    border: 1px solid rgb(251, 76, 76);
    text-align: center;
    border-radius: 6px;
    font-size: 18px;
}

.warningk {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    color: #fff;
    border: 1px solid rgb(255, 234, 3);
    text-align: center;
    border-radius: 6px;
    font-size: 18px;
}

.uncompletek {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    color: #fff;
    border: 1px solid rgb(7, 221, 25);
    text-align: center;
    border-radius: 6px;
    font-size: 18px;
}

.succesk {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    color: #fff;
    border: 1px solid rgb(7, 221, 25);
    text-align: center;
    border-radius: 6px;
    font-size: 18px;
}

.erorr-status .el-radio.is-bordered {
    border-color: red !important;
}

.fw-w {
    flex-wrap: wrap !important;
}

.bg10 {
    background: #02b102;
}

.c-green {
    color: #0AD668;
    font-weight: 600 !important;

}

.c-red {
    color: #FF3838 !important;
    font-weight: 600 !important;
}

.c-yellow {
    color: #C4E300 !important;
    font-weight: 600 !important;
}
.c-primary{
    color: #004787;
}

.bb .el-input__inner {
    border: 1px solid rgb(100, 100, 100) !important;
}

.bb .el-input__inner::placeholder {
    color: rgb(100, 100, 100) !important;
    opacity: 1 !important;
}

.bb .el-input__icon {
    color: rgb(100, 100, 100) !important;
}
.upload-file{
    width: 100%;
    .upload-file-text{
        display: flex;
        button{
            background: #004787;
            color: #fff;
            font-weight: 600;
            position: relative;
            min-width: 150px;
            width: 150px;
            font-size: 12px;
            max-height: 40px;
            cursor: pointer;
            padding: 5px 10px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            input{
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
        p{
            font-size: 16px;
            line-height: 20px;

        }
    }
    .upload-file-list{
        padding-top: 15px;
    }
    .upload-file-list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5px;

        p{
            flex: 1;
            padding-right: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

        }
        button{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;
            font-size: 18px;

        }
        .upload-success{
            color: #02b102;
        }
        .upload-delete{
            color: red;
            display: none;
        }
        .upload-progress{
                display: none;
        }
        .contols-upload:hover{
            .upload-success{
                display: none;
            }
            .upload-delete{
                display: block;
            }
        }
        &.progress{

            .upload-success{
                display: none;
            }
            .upload-progress{
                display: block;
            }
            .contols-upload:hover{

            .upload-delete{
                display: none;
            }
        }
        }



    }
    .disabled{
        .upload-file-list-item{
            .contols-upload:hover{
            .upload-success{
                display: block;
            }
            .upload-delete{
                display: none;
            }
        }
        }
    }
    .file-Size{
            padding-top: 10px;
            text-align: right;
            color: green;
    }
}

.el-loading-spinner{
        text-align: center;
    }
// .el-table .warning-row {
//   background: rgb(245, 219, 219) !important;
// }

//  .el-table .success-row {
//   background: rgb(226, 243, 224) !important;
// }

.bb0 {
    border-bottom: 0px;
}

.mha {
    min-height: auto !important;
}

.el-table .el-table__header .has-gutter .cell {
    word-wrap: normal !important;
    word-break: normal !important;
}

.el-table .cell {
    // word-wrap: normal !important;
    word-break: normal !important;
}

.button {
    padding: 10px 20px !important;
    border-radius: 15px !important;
    font-size: 14px;
    &.black {
        color: #fff;
        background: black;
    }
    &.purple{
        color: #fff;
        background: #6366f1;
    }

    &.dark-blue {
        background: #004787;
        color: #fff;
        font-weight: 600;
    }
    &.dark-yellow{
        background: #FFB800;
        color: #fff;
        font-weight: 600;
    }
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fs-20 {
    font-size: 20px;
}

.c-dark-blue {
    color: #004787 !important;
}

.pdf-control {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 20px;
}

.pdf {
    overflow-y: auto;
    padding: 0px 20px;
    padding-bottom: 20px;
    width: 100%;
    position: relative;
    min-height: 500px;
}

.loading-pdf {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.print {
    padding: 10px 20px;
    background: #004787;
    color: #fff;
    border-radius: 15px;
    font-weight: 600;
    font-size: 14px;
}

.play-pause-layer {
    display: none;
}

.table-link-button {
    font-weight: 600;
    cursor: pointer;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-13 {
    font-size: 13px !important;
}



.h-full {
    min-height: 100%;
    height: 100%;
}

.responsive-btn {
    padding: 15px;
}

@media screen and(max-width:750px) {
    .modal-item {
        width: 100%;
        padding: 80px 20px 120px 20px;
    }

    .responsive-btn {
        padding: 10px;
        font-size: 12px !important;
    }

}
.user-modal-body {
  .el-radio__label {
    padding-left: 2px;
    font-size: 13px !important;
  }
  .el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 4px;
  }
}
</style>
<style scoped>
.config-cadaster {
    position: fixed;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    left: 0;
    overflow: auto;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    box-sizing: border-box;
    padding: 20px 0px;

}

.cadastre-scroll {
    padding: 50px 10px;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
}

.config-cadaster-item {
    max-width: 500px;

}

.title-config {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
}

.config-text {
    font-size: 14px;
    font-weight: 500;
    margin: 2px 0px;
}

.config-text td {
    padding: 1px 5px 1px 0px;
}

.title2-config {
    font-size: 18px;
    margin: 5px 0px;
    font-weight: 500;
}

.config-text2 {
    margin: 10px 0px;
    font-size: 16px;
    font-weight: 500;
    color: green;
    text-align: center;
}

.config-button {
    padding: 15px 20px;
    font-weight: 500;
    background: #004787 !important;
    color: #fff;
    margin-top: 10px;
    border-radius: 15px;
}
.router-content{
    position: relative;
    min-height: calc(100vh - 80px);
    padding: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.pb-80{
    padding-bottom: 80px;
}
.app-footer-container{
    padding-left: 20px;
    padding-right: 20px;
}
.nav-bottom{
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 15px;
    background: #fff;
    background: #004787;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.nav-bottom-div{
    padding: 10px;

}
.nav-bottom p{
    font-size: 12px;
    color: #ffff;
}
.unicon-link{
    display: flex;

}
.owf-h{
    overflow:hidden !important;
}


.el-select-dropdown__item{
   text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 160px !important;
  height: 1.2em !important;
  white-space: nowrap !important;
  color:red !important;
}


/* TAPLE */

.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
    border-color: #fff !important;
}


</style>
