<template>
<div class="first-enter" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="first-enter-text" v-if="page==1">
        <div class="top-button">
            <button @click="page=2"> {{$t('next')}} </button>
            <button @click="Logout()"> {{$t('logout')}} </button>
        </div>
        <h1>{{$t('hi')}}, <span>«{{$t('app_name')}}»</span></h1>
        <p>{{$t('use_add_user_data')}} </p>
        <div class="w-full flex justify-center ">
            <button class="button-item" @click="page=2"> {{$t('add_data')}} </button>
        </div>
    </div>
    <div class="user-data" v-if="page==2">
        <div class="top-button">
            <button @click="page=1">{{$t('back')}}</button>
            <button @click="page=3" v-if="obj.profile_for_user.id">{{$t('next')}}</button>
            <button @click="Logout()"> {{$t('logout')}} </button>
        </div>
        <div class="flex w-full  " style="margin-top:20px;">
            <div class="user-left ">
                <div class="w-full flex justify-center">
                    <div class="user-img">
                        <div class="img-content">
                            <img :src="img ? img : require(`@/assets/img/icons/freeuser.svg`)" alt="AA">
                        </div>
                        <div class="change-img">
                            <button type="button">
                                <img src="@/assets/img/icons/edit-img.svg" alt="">
                                <input type="file" accept="image/*" @change="uploadImage($event)">
                            </button>
                        </div>

                    </div>
                </div>
                <div class="user-names">
                    <!-- <p class="name">Alisher Parpiev Adxamovich </p> -->
                    <!-- <p class="second-title">{{me.login_name}}</p>
                    <p class="second-title"  >@ali0199</p> -->
                </div>
            </div>
            <div class="user-right">
                <h1 class="title-h">{{$t('add_owner_data')}}:</h1>
                <el-form :model="obj" :rules="ObjRules" ref="userform" class="fs-10">
                    <div class="w-full pt-8">

                        <el-row :gutter="24" class="mb-2 flex items-center user-input-item">
                            <el-col :md="10">
                                <p class=" el-form-item "><span>*</span>{{$t('first_name')}}:</p>
                            </el-col>
                            <el-col :md="14">
                                <el-form-item label="" label-width="0" prop="profile_for_user.name">
                                    <el-input style="max-width:450px;" :placeholder="`*${$t('first_name')}`" v-model="obj.profile_for_user.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="mb-2 flex items-center user-input-item">
                            <el-col :md="10">
                                <p class="el-form-item"><span>*</span>{{$t('last_name')}}:</p>
                            </el-col>
                            <el-col :md="14">
                                <el-form-item label="" label-width="0" prop="profile_for_user.surname">
                                    <el-input style="max-width:450px;" :placeholder="`*${$t('last_name')}`" v-model="obj.profile_for_user.surname"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="mb-2 flex items-center user-input-item">
                            <el-col :md="10">
                                <p class="el-form-item"><span>*</span>{{$t('mid_name')}}:</p>
                            </el-col>
                            <el-col :md="14">
                                <el-form-item label="" label-width="0" prop="profile_for_user.middlename">
                                    <el-input style="max-width:450px;" :placeholder="`*${$t('mid_name')}`" v-model="obj.profile_for_user.middlename"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="mb-2 flex items-center user-input-item">
                            <el-col :md="10">
                                <p class="el-form-item">{{$t('phone_munber')}}:</p>
                            </el-col>
                            <el-col :md="14">
                                <el-form-item label="" label-width="0" prop="profile_for_user.phone_number">
                                    <el-input style="max-width:450px;" v-mask="'+998 ##-###-##-##'" :placeholder="`*${$t('phone_munber')}`" v-model="obj.profile_for_user.phone_number"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="mb-2 flex items-center user-input-item">
                            <el-col :md="10">
                                <p class="el-form-item">{{$t('user_email')}}:</p>
                            </el-col>
                            <el-col :md="14">
                                <el-form-item label="" label-width="0" prop="profile_for_user.email">
                                    <el-input style="max-width:450px;" :placeholder="`*${$t('user_email')}`" v-model="obj.profile_for_user.email"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24" class="mb-2">
                            <el-col :md="24" class="flex   user-config-text">
                                <el-checkbox v-model="userconfig" class="mr-2"></el-checkbox>
                                <p class="el-form-item ml-2 cp" v-on:click="userconfig=!userconfig" :class="userconfig? 'c-green':''">{{$t('true_owner_data')}}</p>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24" class="mt-5 flex items-center " v-if="userconfig">
                            <el-col :md="24">
                                <div class="w-full flex justify-end">
                                    <button class="change-button" type="button" @click="save()"> <i class="el-icon-document-checked mr-1"></i> {{$t('save')}} </button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="video-info" v-show="page==3">
        <div class="top-button">
            <button @click="page=2">{{$t('back')}}</button>
            <button @click="page=4" v-if="obj.profile_for_user.id">{{$t('next')}}</button>
            <button @click="Logout()"> {{$t('logout')}} </button>
        </div>
        <div class="bg1">
            <img width="100%" src="@/assets/img/1.png" alt="AA">
        </div>
        <div class="w-full video-info-item">
            <video controls ref="video" src="https://pasportbino.uz/uploads/video-guide/video.mp4"> </video>
        </div>
        <div class="left-controls">
            <div class="video-play-button">
                <a href="https://pasportbino.uz/uploads/video-guide/video.mp4" target="_blank" download>
                    <button type="button">
                        <i class="el-icon-download" style="font-weight:600; font-size:20px;"></i>
                    </button>
                </a>
            </div>

        </div>
    </div>
    <div class="page-info" v-if="page==4">
        <div class="top-button">
            <button @click="page=3">{{$t('back')}}</button>
            <button @click="Logout()"> {{$t('logout')}} </button>
        </div>
        <div class="bg1">
            <img width="100%" src="@/assets/img/1.png" alt="AA">
        </div>
        <div class="page-info-item">
            <h1 class="about-title">{{$t('about')}}</h1>
            <div class="page-info-texts" v-on:scroll="Scroll($event)" ref="scrolled">
                <div class="w-full pr " style="padding:10px 10px;  ">

                    <p class="text-p">
                        {{$t('system_about')}}
                    </p>
                    <p class="title-p">{{$t('system_task')}}:</p>
                    <table class="ul-table">
                        <tr>
                            <td class="number-td">1.</td>
                            <td class="text-td"> {{$t('system_task1')}} </td>
                        </tr>
                        <tr>
                            <td class="number-td">2.</td>
                            <td class="text-td">{{$t('system_task2')}}</td>
                        </tr>
                        <tr>
                            <td class="number-td">3.</td>
                            <td class="text-td">{{$t('system_task3')}} </td>
                        </tr>
                        <tr>
                            <td class="number-td">4.</td>
                            <td class="text-td">{{$t('system_task4')}}</td>
                        </tr>
                        <tr>
                            <td class="number-td">5.</td>
                            <td class="text-td">{{$t('system_task5')}}</td>
                        </tr>
                    </table>

                    <p class="title-p">{{$t('system_the_goal')}}:</p>
                    <table class="ul-table">
                        <tr>
                            <td class="number-td">1.</td>
                            <td class="text-td">{{$t('system_the_goal1')}}</td>
                        </tr>
                        <tr>
                            <td class="number-td">2.</td>
                            <td class="text-td">{{$t('system_the_goal2')}}</td>
                        </tr>
                        <tr>
                            <td class="number-td">3.</td>
                            <td class="text-td">{{$t('system_the_goal3')}} </td>
                        </tr>
                        <tr>
                            <td class="number-td">4.</td>
                            <td class="text-td">{{$t('system_the_goal4')}} </td>
                        </tr>
                        <tr>
                            <td class="number-td">5.</td>
                            <td class="text-td">{{$t('system_the_goal5')}} </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="w-full check-items">
                <div class="check-item">
                    <div>
                        <el-checkbox v-model="checked" class="flex items-center check-item-input"> {{$t('acquainted_system')}} </el-checkbox>
                    </div>
                    <div>
                        <button class="btn-default" @click="Reload" v-if="checked"> {{$t('login_system')}} </button>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
export default {
    data() {
        return {
            page: 1,
            activePdf: 1,
            loading: false,
            video: false,
            img: '',
            imageFile: '',
            checked: false,
            userconfig: false,
            obj: {
                profile_for_user: {
                    name: '',
                    surname: '',
                    middlename: '',
                    phone_number: '',
                    email: '',
                }
            },
            ObjRules: {
                profile_for_user: {
                    name: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }, ],
                    surname: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    middlename: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    phone_number: [{
                            required: true,
                            message: this.$t('add_phone_number'),
                            trigger: "change",
                        },
                        {
                            min: 17,
                            message: this.$t('add_phone_number'),
                            trigger: 'change'
                        }
                    ],
                    email: {
                        type: 'email',
                        message: this.$t('add_email'),
                        trigger: "change",
                    },
                }
            },
        }
    },

    computed: {
        me() {
            return this.$store.state.me
        },
    },
    watch: {
        me(val) {
            this._Get()
        }
    },
    methods: {
        save() {
            this.$refs.userform.validate((valid) => {
                if (valid) {
                    this.loading = true
                    if (this.imageFile) {
                        this.obj.profile_for_user.image = this.imageFile
                        this.imageFile = ''
                    } else {
                        delete this.obj.profile_for_user.image
                    }
                    axios.patch(`/users/${this.me.id}/`, this.obj)
                        .then((response) => {
                            this._Get()
                            this.page = 3
                            this.$refs.video.play()
                        })
                        .catch(e => {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('error_letter_redail'),
                                timer: 3000
                            });
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            })
        },
        uploadImage(event) {
            this.img = URL.createObjectURL(event.target.files[0])
            const file = event.target.files[0];
            toBase64(file).then((resp) => {
                this.imageFile = resp
            });

        },
        _Get() {
            this.loading = true
            axios.get(`/users/${this.me.id}/`)
                .then((response) => {
                    delete response.data.profile_for_user.user
                    this.img = response.data.profile_for_user.image
                    this.obj = response.data;
                })
                .finally(() => {
                    this.loading = false
                })
        },
        Pause() {
            this.$refs.video.pause()
            this.video = false
        },
        Play() {
            this.$refs.video.play()
            this.video = true
        },
        Reload() {
            location.reload();
        },
        Scroll() {
            if ((parseInt(this.$refs.scrolled.clientHeight) - parseInt(this.$refs.scrolled.scrollTop)) < 170) {

                this.checked = true
            }
        },
        Logout() {
            this.$router.push("/login")
                .catch(e => {
                    if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                        console.log(e)
                    }
                });
        }
    },
    mounted() {
        if (this.me.id) {
            this._Get()
        }
        this.$refs.video.pause()

    }

}
</script>

<style scoped>
.user-config-text {
    font-size: 14px;
    font-weight: 500;
    color: #ee0f08;

}

.el-form-item {
    margin-bottom: 10px !important;
}

.el-form-item__error {
    font-size: 10px !important;
}

.top-button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 100;
}

.top-button button {
    padding: 15px 20px;
    background: #fff;
    margin-right: 10px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    background: #004787;
    border-radius: 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

}

.btn-default {
    padding: 15px 20px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    background: #004787 !important;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

}

.first-enter {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.first-enter-text {
    max-width: 700px;
}

.first-enter-text h1 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.first-enter-text h1 span {
    font-weight: 700;
}

.first-enter-text p {
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

.first-enter-text .button-item {
    padding: 15px 25px;
    margin-top: 20px;
    background: #004787;
    border-radius: 15px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.user-data {
    width: 900px;
}

.user-left {
    width: 240px;
    margin-right: 10px;
    padding: 0px 20px;
}

.user-right {
    flex: 1;
}

.user-img {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    border-radius: 50%;
    position: relative;
}

.user-img .img-content {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    border-radius: 50%;
    object-position: center;
    overflow: hidden;
}

.user-img .img-content img {
    width: 100%;
}

.title-h {
    font-size: 25px;
    font-weight: 700;

}

.change-img {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.change-img button {
    position: relative;
}

.change-img button input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

.user-names p.name {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
}

.second-title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.role-user {
    font-size: 10px;
    font-weight: 600;
}

.user-input-item p {
    font-weight: 600;
}

.change-button {
    padding: 0px 25px;
    background: #004787;
    border-radius: 15px;
    color: #fff; 
    font-weight: 600;
    height: 48px !important;
    font-size: 14px;
}

.page-info {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

}

.page-info-item {
    height: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 10;

}

.page-info-texts {
    padding: 20px;
    max-width: 800px;
    height: 70%;
    overflow-y: auto;
}

.page-info-texts::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(196, 196, 196);
}

.page-info-texts::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(196, 196, 196);
}

.page-info-texts::-webkit-scrollbar-thumb {
    background-color: rgb(109, 109, 109);
    border: 2px solid rgb(109, 109, 109);
}

.page-info-texts button {
    padding: 0px 25px;
    background: #367b8a;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
    height: 48px !important;
    font-size: 14px;
    margin-top: 20px;
}

.left-controls {
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 10;
}

.pdf-chager {
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;

}

.pdf-chager button {
    background: #ffff;
    color: #000;
    font-weight: 600;
}

.active-pdf-lang {
    background: #004787 !important;
    color: #fff !important;
}

.video-play-button {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.video-play-button button {
    width: 60px;
    height: 60px;
    background: #004787 !important;
    color: #fff !important;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-item {
    position: relative;
    width: 80%;
    background: #fff;
    height: 80%;
    position: relative;
}

.close-video {
    z-index: 100;
    position: absolute;
    top: -5px;
    right: -5px;
}

.close-video button {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-video button i {
    font-weight: 600;
}

.play-pause-layer {
    display: none !important;
}

/* About */
.check-items {
    padding: 0px 0px 0px 20px;
}

.check-item {
    border-top: 1px solid rgb(196, 196, 196);
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 0px;
    height: 91px;
}

.check-item-input {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
}

.about-title {
    font-weight: 600;
    padding: 20px;
    font-size: 20px;
}

.title-p {
    font-weight: 600;
    font-size: 16px;
    margin: 20px 0px 10px 0px;
}

.bgf {
    background: #fff;
}

.pr {
    position: relative;
}

.bg1 {
    position: absolute;
    opacity: 0.2;
    bottom: -60px;
    right: -150px;
    width: 80%;

}

.about-ul {
    list-style-type: decimal;
    padding: 15px;
}

.about-ul::marker {
    font-weight: 600 !important;
    color: red;
}

.number-td {
    padding: 10px 10px 10px 0px;
    font-size: 16px;
    font-weight: 600;
    vertical-align: top;
    padding-left: 20px;

}

.ul-table {
    width: 100%;

}

.text-td {
    padding: 10px;
    vertical-align: top;
    font-size: 16px;

}

.text-p {
    font-size: 16px;
}

.video-info {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.video-info-item {
    height: 80%;
    width: 80%;
    position: relative;
    z-index: 10;

}
</style>
