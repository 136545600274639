<template>
    <div class="app-nav-container">
        <div class="AppNavbar" :class="sidebarToggle ? '' : 'activenav'">
            <div class="left flex items-center gap-5">
                <!-- <img src="@/assets/img/Emblem_of_Uzbekistan.svg">
            <h1> {{$t('app_name')}} <span style="color:yellow;">[ beta ]</span> </h1> -->
                <button @click="setSidebarToggle()">
                    <iconBurger />
                </button>
                <h1>{{ findName() }}</h1>
            </div>
            <div class="right">
                <el-dropdown size="small" trigger="click">
                    <el-button size="small" class="h-40 border-radius-20" v-if="$i18n.locale == 'cyr'"><img
                            style="margin-right: 10px;width:22px;" src="@/assets/img/uzb.svg"> Ўзбекча <i
                            class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-button size="small" class="h-40 border-radius-20" v-else><img
                            style="margin-right: 10px;width:22px;" src="@/assets/img/oris.svg">Русский <i
                            class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>
                                <div @click="$i18n.locale = 'cyr'"
                                    style="display: flex;align-items: center;justify-content: flex-start;"><img
                                        style="margin-right: 5px;width: 22px;" src="@/assets/img/uzb.svg">Ўзбекча</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="$i18n.locale = 'ru'"
                                    style="display: flex;align-items: center;justify-content: flex-start;"><img
                                        style="margin-right: 5px;width: 22px;" src="@/assets/img/oris.svg">Русский</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-dropdown size="small" trigger="click">

                    <el-button class="user-lenk-button border-radius-20" size="small" style="margin-left: 10px;">
                        <div class="user-img"><img
                                :src="obj.profile_for_user.image ? obj.profile_for_user.image : require('@/assets/img/icons/freeuser.svg')">
                        </div> {{ me.login_name }} <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>
                                <div @click="$router.push('/me')"
                                    style="display: flex;align-items: center;justify-content: flex-start;"> <i
                                        class="el-icon-user-solid"
                                        style="font-size:14px; width:14px; margin-right:8px;"></i> {{ $t('accaunt') }}
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="logout()"
                                    style="display: flex;align-items: center;justify-content: flex-start;"><img
                                        src="@/assets/img/log-out.svg" style="width:12px; margin-right:8px;">
                                    {{ $t('logout') }}</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>
<script>
import iconBurger from './Icons/iconBurger.vue';
export default {
    components: {
        iconBurger
    },
    data() {
        return {
            obj: {
                profile_for_user: {}
            },

        }
    },
    computed: {
        me() {
            return this.$store.state.me
        },
        sidebarToggle() {
            return this.$store.state.sidebarToggle
        },
        routerLinkes() {
            let data = {
                'me': this.$t('users'),
                'statistica': this.$t('statistics'),
                'home': this.$t('statistics'),
                'report': this.$t('report'),
                'org': this.$t('organizations'),
                'object': this.$t('objects'),
                'obj': this.$t('objects'),
                'building': this.$t('buildings'),
                'reconstruction': this.$t('buildings'),
                'build': this.$t('buildings'),
                'unconfirmed-buildings': this.$t('unconfirmed_buildings'),
                'transport': this.$t('transport_facilities'),
                'investment': this.$t('investment_project'),
                'user': this.$t('users'),

                'help': this.$t('help'),
                'about': this.$t('about'),

                'reservoir': this.$t('reservoirs'),
                'water': this.$t('reservoirs'),
                'hydrounit': this.$t('reservoirs'),
                'pumpingstation': this.$t('reservoirs'),
            }
            return data
        }
    },
    watch: {
        me() {
            if (this.me.id) {
                this._Get()
            }
        },
    
    },
    methods: {
        setSidebarToggle() {
            this.$store.commit('setSidebarToggle', !this.sidebarToggle)
        },
        logout() {
            axios.post('/logout/', {
                refresh: localStorage.refresh
            })
            // this.$store.commit('get_me', { organization: {} }) 
            localStorage.token = '';
            localStorage.refresh = '';
            localStorage.role = '';
            this.$router.push('/login')
                .catch(e => {
                    if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                        console.log(e)
                    }
                });

        },
        _Get() {
            this.loading = true
            axios.get(`/users/${this.me.id}/`)
                .then((response) => {
                    delete response.data.profile_for_user.user
                    this.img = response.data.profile_for_user.image
                    this.obj = response.data;
                })
                .finally(() => {
                    this.loading = false
                })
        },
        findName() {
            let data = ''
            for (let i of Object.keys(this.routerLinkes)) {
                if (this.$route.name.includes(i)) {
                    data = this.routerLinkes[i]
                }
            }
            return data
        }
    },
    mounted() {
        // if (this.me.id) {
        //     this._Get()
        // }

        this.$root.$on("ChangeImage", () => {
            return this._Get()
        });

    },
}
</script>

<style lang="scss">
.AppNavbar {
    position: fixed;
    z-index: 1000;
    padding-left: 280px;
    z-index: 10;
    top: 0;
    left: 0;
    transition: all .2s linear;
    max-width: 2000px;

    &.activenav {
        padding-left: 70px;
    }

    .el-dropdown-menu__item {
        display: flex;
        align-items: center;

        img {
            margin-right: 5px;
        }
    }

    .el-button {
        span {
            display: flex;
            align-items: center;

            img {
                margin-right: 5px;
            }
        }
    }
}

@media screen and(min-width: 2000px) {
    .AppNavbar {
        top: 0;
        left: auto;
        padding-left: 0px;

        &.activenav {
            padding-left: 0px;
        }
    }
}
</style>
<style lang="scss" scoped>
.app-nav-container {
    height: 70px;
    left: 0;
}

.AppNavbar {
    height: 70px;
    width: 100vw;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // border-bottom: 1px solid #eeee;
    .left {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        height: 100%;

        h1 {
            font-weight: 600;
            color: #004787;
            font-size: 20px;
        }
    }

    .right {
        height: 100%;
        padding: 10px 30px;
        display: flex;
        align-items: center;
    }

    h1 {
        font-weight: 500;
        font-size: 16px;
        max-width: 700px;
        text-align: left;
        line-height: 17px;
        color: #F4F4F4;
    }
}

.user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.user-img img {
    width: 100%;
    object-position: center;
}

.user-lenk-button {
    padding: 0;
    padding: 0 10px;
    height: 40px;
}
</style>
