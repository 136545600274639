<template>
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2H22" stroke="#004787" stroke-width="3" stroke-linecap="round"/>
        <path d="M2 10H13" stroke="#004787" stroke-width="3" stroke-linecap="round"/>
        <path d="M2 18H22" stroke="#004787" stroke-width="3" stroke-linecap="round"/>
    </svg>
</template>
<script>
export default{
    props:['active']
}



</script>