import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import axios from 'axios'
import axiosref from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
global.axios = axios;

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)


let isRefreshing=false
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

axios.interceptors.response.use(
    function(responce){
        isRefreshing=false
        return responce
    },
    function (error){
        const orginal = error.config
        if((error.response.data.code=="token_not_valid" && error.config.url=='/token/refresh/')|| (error.config.url=='/token/refresh/' && error.response.status)){
            if(router.currentRoute.name!='check'){
                router.push("/login").catch(e => { if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) { console.log(e) }});
            }

        }
        if ((error.response.status === 401) && !orginal._retry && router.currentRoute.name!='login' ) {

            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                failedQueue.push({resolve, reject})
                }).then(token => {
                orginal.headers['Authorization'] = 'Bearer ' + token;
                return axios(orginal);
                }).catch(err => {
                return Promise.reject(err);
                })
            }
            isRefreshing=true
            orginal._retry=true
            return new Promise(function (resolve, reject) {
                axios.post('/token/refresh/', {refresh: localStorage.refresh}).then(res=>{
                        localStorage.token = res.data.access;
                        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;
                        orginal.headers["Authorization"] = `Bearer ${res.data.access}`;
                        processQueue(null, res.data.access);
                        resolve(axiosref(orginal));
                        })
                        .catch(e=>{
                            processQueue(e, null);
                            reject(e);
                        })
                        .finally(()=>{
                            isRefreshing=false;
                        })

                    // return axiosref(orginal);

            })

        }


    return Promise.reject(error)


})

var numeral = require("numeral");

Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
  });

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';
Vue.use(ElementUI, { locale });

import AppNavbar from './components/AppNavbar.vue';
import AppAside from './components/AppAside.vue';
import First from './components/First.vue';

import YmapPlugin from 'vue-yandex-maps';

import './index.css';
import './element.scss';


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VuePdfApp from "vue-pdf";
import "vue-pdf-app/dist/icons/main.css";
Vue.component("vue-pdf", VuePdfApp);


const settings = {
    apiKey: '',
    lang: 'ru_RU',
    coordorder: 'latlong',
    controls: ['trafficControl'],
    version: '2.1'
}
Vue.use(YmapPlugin, settings)



Vue.config.productionTip = false;
Vue.component('AppNavbar', AppNavbar)
Vue.component('AppAside', AppAside)
Vue.component('First', First)




if(router.currentRoute.name!='check'&&router.currentRoute.name!='login' && localStorage.token!=null){ store.dispatch("get_me")}
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
