import axios from 'axios';
import axios2 from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'
axios2.defaults.headers.common["Authorization"] = `Bearer AAAAA`;

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        reservoirIsCreated: false,
        reservoirIsEdit:false,
        activeName:['1'],
        disabletItem:false,
        Coord:'',
        role: localStorage.role? localStorage.role:'',
        version:require('./../../package.json').version,
        back_ver:'1.0.0',
        me: {
            organization: {}
        },
        first:false,
        profile:{},
        denied_reservoir_api: ['anti_seismic_struts','water_basin','technical_condition','condition_of_supports','base_material','material_of_support','intermediate_device_material', 'structure_scheme', 'meet_requirements', 'repaired_type', 'repaired', 'type_transport_facility', 'buildinggroup', 'road_category', 'barrier_type', 'type_static_schema', 'type_by_length',    'earthquakecondition', 'currency', 'balancevalue',   'foundationtype', 'loadbearing', 'coverings', 'roofsection', 'properties', 'communicationengineering', 'dailycondition', 'loadbearingstatus', 'reconstructiondurability', 'soiltype', 'constructivesolution',  'plannedshape', 'dividedintosections', 'presence', 'concreteclass', 'brickbrand', 'mixtureClasslist', 'projecttype', 'naturaldisasters', 'consequencesnaturaldisasters', 'explosionsafety', 'assessmenttechnicalcondition', 'armatureclass', 'armaturediametr', 'armatureclassdiametr'],
        denied_operator_api: ['type_transport_facility', 'type_static_schema', 'road_category', 'barrier_type', 'type_by_length','anti_seismic_struts','water_basin','technical_condition','condition_of_supports','base_material','material_of_support','intermediate_device_material', 'structure_scheme', 'meet_requirements', 'repaired_type', 'repaired', 'watersource',  'gate_construction', 'dam_type', 'reservoirfunction', 'main_ingredient', 'reservoirtype',   'reservoirclass', ],
        denied_tf_operator_api:['unittype', 'unit', 'finalconclusion', 'watersource',  'gate_construction', 'dam_type', 'reservoirfunction', 'main_ingredient', 'reservoirtype',   'reservoirclass', 'buildinggroup', 'value', 'beltspresence',   'earthquakecondition', 'currency', 'balancevalue',   'foundationtype', 'loadbearing', 'coverings', 'roofsection', 'properties', 'communicationengineering' , 'loadbearingstatus', 'soiltype', 'constructivesolution',  'plannedshape', 'dividedintosections', 'presence', 'concreteclass', 'brickbrand', 'mixtureClasslist', 'projecttype', 'consequencesnaturaldisasters', 'explosionsafety', 'armatureclass', 'armaturediametr', 'armatureclassdiametr'],
        denied_admin_api: [],
        denied_org_admin_api: ['watersource',  'gate_construction', 'dam_type', 'reservoirfunction', 'main_ingredient', 'reservoirtype',   'reservoirclass', 'balancevalue' ],
        denied_ministry_admin_api: ['watersource',  'gate_construction', 'dam_type', 'reservoirfunction', 'main_ingredient', 'reservoirtype',   'reservoirclass', 'balancevalue' ],
        denied_ministry_api: ['gate_construction', 'watersource', 'main_ingredient', 'reservoirfunction', 'dam_type', 'reservoirtype',   'reservoirclass',    ],
        dictionary: {
            unittype: [],
            unit: [],
            value: [],
            region: [],
            district: [],
            buildinggroup: [],
            currency: [],
            balancevalue: [],
            beltspresence: [],
            foundationtype: [],
            loadbearing: [],
            coverings: [],
            roofsection: [],
            properties: [],
            communicationengineering: [],
            loadbearingstatus: [],
            reconstructiondurability: [],
            soiltype: [],
            earthquakecondition: [],
            constructivesolution: [],
            buildingseismicity: [],
            plannedshape: [],
            dividedintosections: [],
            presence: [],
            concreteclass: [],
            brickbrand: [],
            mixtureClasslist: [],
            finalconclusion: [],
            projecttype: [],
            naturaldisasters: [],
            consequencesnaturaldisasters: [],
            explosionsafety: [],
            assessmenttechnicalcondition: [],
            armatureclass: [],
            armaturediametr: [],
            armatureclassdiametr: [],
            reservoirclass: [],
            reservoirtype:[],
            dam_type:[],
            reservoirfunction:[],
            main_ingredient:[],
            watersource:[],
            gate_construction:[],

            structure_scheme:[],
            meet_requirements:[],
            repaired_type:[],
            repaired:[],
            type_transport_facility:[],
            road_category:[],
            barrier_type:[],
            type_static_schema:[],
            type_by_length:[],
            intermediate_device_material:[],
            material_of_support:[],
            base_material:[],
            technical_condition:[],
            condition_of_supports:[],
            anti_seismic_struts:[],
            water_basin:[],
            railway_line:[],
            facility_type:[],
            railway_route:[],
            number_of_path:[],
            transition_classification:[],
            obstacle_name:[],
            railway_intermediate_device_material:[],
            railway_intermediate_device_type:[],
            railway_base_material:[],
            slope_type:[],
            facility_location:[],
            polotno_type:[],
            foundation:[],
            strengthening_work:[],
            condition_of_facility:[],
            condition_of_earthquake:[],
            dailycondition: [],
            road_name: [],
            road_name_option: [],








        },
        units:{
            unit_1: [],
            unit_2: [],
            unit_3: [],
            unit_4: [],
        },
        statusUnattested: false,
        unapproved_buildings: 0,
        sidebarToggle:true
    },
    mutations: {
        setStatusUnattested(state, payload) {
            state.statusUnattested = payload
        },
        set_coord(state, payload) {
            state.Coord = payload;
        },
        set_activename(state, payload) {
            state.activeName= payload;
        },
        set_disabletItem(state, payload) {
            state.disabletItem= payload;
        },
        reservoirIsCreated(state, payload) {
            state.reservoirIsCreated = payload;
        },
        reservoirIsEdit(state, payload) {
            state.reservoirIsEdit = payload;
        },
        set_dictionary(state, { dictionary, type }) {
            state.dictionary[type] = dictionary;
        },
        set_units(state, { units, type } ){
            state.units[type] = units;
        },
        get_me(state, { payload }) {
            state.me = payload;
        },
        get_first(state, { payload }) {
            state.first = payload;
        },
        get_profile(state, { payload }) {
            state.profile = payload;
        },

        set_role(state, payload){
            state.role=payload
        },
        set_back_ver(state, payload) {
            state.back_ver = payload;
        },

        set_unapproved_buildings(state, { new: newBuildings, rejected, review }) {
            state.unapproved_buildings = 0
            state.unapproved_buildings += newBuildings + rejected + review
            console.log(newBuildings, rejected, review)
        },
        setSidebarToggle(state, val){
            state.sidebarToggle=val
        }
    },
    actions: {
        get_unapproved_buildings({commit}) {
            axios.get(`building/specificationscount/`)
                .then((res) => {
                    commit('set_unapproved_buildings', res.data)
                })
        },
        get_dictionary({ commit, state }) {
            Object.keys(state.dictionary).filter(resp => {
                if (!state[`denied_${localStorage.role}_api`].includes(resp)) {
                    axios.get(`/dictionary/${resp}/`)
                        .then((response) => {
                            commit('set_dictionary', {
                                dictionary: response.data,
                                type: resp
                            })
                        })
                }

            })
            if(!state[`denied_${localStorage.role}_api`].includes('unit'))
            {
                for (let i = 1; i <= 4; i++) {
                    axios.get(`/dictionary/unit/?unittype=${i}`)
                        .then((response) => {
                            commit('set_units', {
                                units: response.data,
                                type: `unit_${i}`
                            })
                        })
                }
            }

        },
        get_me({ commit, state, dispatch  }) {
            axios.get('/users/me/',{
                headers: {
                    Authorization : `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then((response) => {
                    commit('get_me', { payload: response.data })
                    dispatch('get_dictionary')
                    if(response.data.id){
                    axios.get(`/users/${response.data.id}/`)
                    .then(res=>{

                        if( res.data.profile_for_user==null){
                            commit('get_first', {
                                payload: true
                            })
                        }
                        else{
                            commit('get_first', {
                                payload: false
                            })
                        }
                        commit('get_profile', {
                            payload: res.data
                        })
                    })
                }
                    commit('set_role', response.data.role.name)
                })
        },
        get_version({commit, state}){
            axios2.get().then(res=>{ commit('set_back_ver', res.data.version)})
        }


    },
    getters: {
        getStatusUnattested(state) {
            return state.statusUnattested
          }
    }

})
