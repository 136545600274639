import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/objects',
        name: 'object',
        component: () =>
            import ('../views/Objects/ObjectList.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/Login/Login3.vue')
    },
    {
        path: '/check',
        name: 'check',
        component: () =>
            import ('../views/check/index.vue')
    },
    {
        path: '/org-list/:org/:id',
        name: 'org-child-first',
        component: () =>
            import ('../views/Admin/OrganisationChildList.vue')
    },
    {
        path: '/org-list/:org/:id/:type',
        name: 'org-child-second',
        component: () =>
            import ('../views/Admin/OrganisationChildList.vue')
    },
    {
        path: '/org-list',
        name: 'org_list',
        component: () =>
            import ('../views/Admin/OrganisationList.vue')
    },
    {
        path: '/org-list/:org',
        name: 'org_list-id',
        component: () =>
            import ('../views/Admin/OrganisationList.vue')
    },
    // {
    //   path: '/userlist',
    //   name: 'userlist',
    //   component: () => import('../views/Admin/UserList.vue')
    // },
    // {
    //   path: '/user-create',
    //   name: 'user-create',
    //   component: () => import('../views/Admin/UserCreate.vue')
    // },
    // {
    //   path: '/user-update',
    //   name: 'user-update',
    //   component: () => import('../views/Admin/UserUpdate.vue')
    // },

    //building statistica
    {
        path: '/statistica/building/:type',
        name: 'statistica-building',
        component: () =>
            import ('../views/Building/BuildingListStatistica.vue')
    },
    {
        path: '/statistica/buildings/uncomplate',
        name: 'uncomplate-building',
        component: () =>
            import ('../views/Building/UnComplate.vue')
    },
    {
        path: '/statistica/buildings/uncomplatenew',
        name: 'uncomplate-building-new',
        component: () =>
            import ('../views/Building/UnComplateNew.vue')
    },
    {
        path: '/statistica/regionbuildings/:region_id/:district_id/:org2',
        name: 'regionbuildings',
        component: () =>
            import ('../views/Building/RegionStatisticBuldingList.vue')
    },
    {
        path: '/objects/object-create',
        name: 'obj-create',
        component: () =>
            import ('../views/Objects/ObjectCreate.vue')
    },
    {
        path: '/objects/object-update/:id',
        name: 'obj-update',
        component: () =>
            import ('../views/Objects/ObjectCreate.vue')
    },
    {
        path: '/objects/object-info/:id',
        name: 'obj-info',
        component: () =>
            import ('../views/Objects/ObjectCreate.vue')
    },
    {
        path: '/objects/building-list/:id',
        name: 'building-list-one',
        component: () =>
            import ('../views/Building/BuildingList.vue')
    },
    {
        path: '/objects/changed-building-list/:id',
        name: 'changed-building-list',
        component: () =>
            import ('../views/Building/ChangedBuildingList.vue')
    },
    {
        path: '/cadaster-changed-building',
        name: 'cadaster-changed-building',
        component: () =>
            import ('../views/Building/CadasterChangedBuilding.vue')
    },
    {
        path: '/building-list/:type',
        name: 'building-list-type',
        component: () =>
            import ('../views/Building/BuildingListNewExist.vue')
    },
    {
        path: '/unattested-building-list/:type',
        name: 'unattested-building-list-type',
        component: () =>
            import ('../views/Building/UnattestedBuildings.vue')
    },
    {
        path: '/unattested-building-list',
        name: 'unattested-building-list',
        component: () =>
            import ('../views/Building/UnattestedBuildings.vue')
    },
    {
        path: '/building-list',
        name: 'building-list',
        component: () =>
            import ('../views/Building/BuildingListNewExist.vue')
    },
    {
        path: '/objects/add-build/:id',
        name: 'add-build',
        component: () =>
            import ('../views/Building/Building.vue')
    },
    {
        path: '/edit-build/:id',
        name: 'edit-build',
        component: () =>
            import ('../views/Building/Building.vue')
    },



    {
        path: '/new-build/:id/:edit',
        name: 'new-build',
        component: () =>
            import ('../views/Building/NewBuild.vue')
    },
    {
        path: '/exist-build/:id/:edit',
        name: 'exist-build',
        component: () =>
            import ('../views/Building/ExistBuild.vue')
    },
    {
        path: '/view-build/:id',
        name: 'view-build',
        component: () =>
            import ('../views/Building/Building.vue')
    },
    {
        path: '/reconstruction',
        name: 'reconstruction',
        component: () =>
            import ('../views/Building/Reconstruction.vue')
    },
    // Reservoir
    // Water
    {
        path: '/reservoir/',
        name: 'reservoirs',
        component: () =>
            import ('../views/Reservoir/Reservoirs.vue')
    },
    {
        path: '/reservoir/:type',
        name: 'reservoirs-type',
        component: () =>
            import ('../views/Reservoir/Reservoirs.vue')
    },
    {
        path: '/reservoir/water/create',
        name: 'water_create',
        component: () =>
            import ('../views/Reservoir/Water/_id.vue')
    },
    {
        path: '/reservoir/water/:id/:edit',
        name: 'water_edit',
        component: () =>
            import ('../views/Reservoir/Water/_id.vue')
    },
    // hydrounits
    {
        path: '/reservoir/hydrounits/create',
        name: 'hydrounits_create',
        component: () =>
            import ('../views/Reservoir/Hydrounits/_id.vue')
    },
    {
        path: '/reservoir/hydrounits/:id/:edit',
        name: 'hydrounits_edit',
        component: () =>
            import ('../views/Reservoir/Hydrounits/_id.vue')
    },
        // Hydroelectricpowerstations
        {
            path: '/reservoir/hydroelectricpowerstations/create',
            name: 'hydroelectricpowerstations_create',
            component: () =>
                import ('../views/Reservoir/Hydroelectricpowerstations/_id.vue')
        },
        {
            path: '/reservoir/hydroelectricpowerstations/:id/:edit',
            name: 'hydroelectricpowerstations_edit',
            component: () =>
                import ('../views/Reservoir/Hydroelectricpowerstations/_id.vue')
        },
    // pumpingstations
    {
        path: '/reservoir/pumpingstation/:id/:edit',
        name: 'pumpingstation-edit',
        component: () =>
            import ('../views/Reservoir/Pumpingstation/_id.vue')
    },
    {
        path: '/reservoir/pumpingstation/create',
        name: 'pumpingstation-create',
        component: () =>
            import ('../views/Reservoir/Pumpingstation/_id.vue')
    },
        // canals

        {
            path: '/reservoir/canals/:id/:edit',
            name: 'canals_edit',
            component: () =>
                import ('../views/Reservoir/Canals/_id.vue')
        },
        {
            path: '/reservoir/canals/create',
            name: 'canals-create',
            component: () =>
                import ('../views/Reservoir/Canals/_id.vue')
        },
    {
        path: '/map',
        name: 'map',
        component: () =>
            import ('../views/Map.vue')
    },
    {
        path: '/help',
        name: 'help',
        component: () => import ('../views/Help/help.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import ('../views/Help/about.vue')
    },
    {
        path: '/',
        name: 'home',
        component: () =>
            import ('../views/Statistic.vue')
    },
    {
        path: '/me',
        name: 'me',
        component: () =>
            import ('../views/User/User.vue')
    },
    {
        path: '/users',
        name: 'users',
        meta:{permission:[ 'admin']},
        component: () =>
            import ('../views/User/index.vue')
    },
    {
        path: '/report',
        name: 'report',
        component: () =>
            import ('../views/Reports.vue')
    },
    {
        path: '/transport',
        name: 'transport',
        meta:{permission:['tf_operator', 'admin', 'org_admin']},
        component: () =>
            import ('../views/Transport/TransportList')
    },
    {
        path: '/transport/add',
        name: 'transportAdd',
        meta:{permission:['tf_operator']},
        component: () =>
            import ('../views/Transport/Add.vue')
    },
    {
        path: '/railway/add',
        name: 'railwayAdd',
        meta:{permission:['tf_operator', 'admin']},
        component: () =>
            import ('../views/Transport/RailwayAdd.vue')
    },
    {
        path: '/railway/edit/:id',
        name: 'railwayEdit',
        meta:{permission:['tf_operator', 'admin']},
        component: () =>
            import ('../views/Transport/RailwayEdit.vue')
    },
    {
        path: '/railway/show/:id',
        name: 'railwayShow',
        meta:{permission:['tf_operator','admin']},
        component: () =>
            import ('../views/Transport/RailwayListShow.vue')
    },
    {
        path: '/transport/show/:id',
        name: 'transportInfo',
        meta:{permission:['tf_operator', 'admin', 'org_admin']},
        component: () =>
            import ('../views/Transport/show/_id.vue')
    },
    {
        path: '/tree',
        name: 'treeData',
        component: () => import ('../views/TreeData/TreeData.vue')
    },
    {
        path: '/transport/edit/:id',
        name: 'transportEdit',
        meta:{permission:['tf_operator', 'admin']},
        component: () =>
            import ('../views/Transport/edit/_id.vue')

    },
    {
        path: '/investment/:id_invest',
        name: 'investment',
        component: () =>
            import ('../views/Investment/index.vue')

    },
    {
        path: '**',
        name: 'notFoundPage',
        component: () =>import ('../views/404/404.vue')
    },
  

]


const router = new VueRouter({
    base: process.env.BASE_URL,
    mode:'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})
router.beforeEach((to, from, next) => {



    if (to.name !== 'login'&&to.name !== 'check' && !localStorage.token) next({ name: 'login' })
    else{
        if(to.meta.permission){
            if(to.meta.permission.includes(localStorage.role)){
                 next()
            }
            else{
                next('/404')
            }
        }
        else{
            next()
        }

    };

})

export default router
